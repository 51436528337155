import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 992.000000 991.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,991.000000) scale(0.100000,-0.100000)" >


<path d="M857 9573 c-3 -5 -8 -24 -13 -43 -10 -45 -9 -40 -22 -73 -7 -16 -12
-30 -12 -31 0 -1 22 -1 50 0 43 3 48 1 44 -14 -5 -13 -4 -14 4 -3 5 7 24 15
41 17 25 3 31 0 31 -16 0 -18 -7 -20 -84 -20 -46 0 -86 4 -88 9 -1 5 -37 -101
-79 -235 l-76 -244 127 1 127 1 37 126 c46 157 45 156 51 150 7 -7 85 -269 85
-285 0 -9 -17 -13 -54 -13 -29 0 -67 -3 -84 -6 -28 -6 -30 -10 -34 -55 -1 -27
0 -50 4 -52 5 -1 -59 -2 -141 -2 -82 0 -147 3 -144 8 2 4 1 7 -4 7 -4 0 -16
-26 -25 -58 -9 -32 -20 -65 -23 -75 -6 -15 16 -16 294 -16 l301 0 0 44 0 45
145 0 145 0 0 54 0 53 -90 -4 -90 -4 0 61 0 61 118 -3 117 -3 0 -49 0 -48 100
4 c86 4 110 9 165 35 154 74 234 235 209 423 -11 80 -58 178 -105 219 l-35 31
-334 0 -335 0 -1 -57 c0 -32 -3 -52 -6 -45 -3 8 -26 12 -67 12 -36 0 -68 -5
-75 -12 -11 -10 -14 -10 -18 0 -3 6 -15 12 -28 12 -13 0 -26 6 -28 13 -4 10
-6 10 -6 0 -1 -9 -18 -13 -51 -13 -43 0 -50 3 -50 19 0 11 5 23 10 26 6 3 10
1 10 -6 0 -9 3 -9 14 0 9 8 16 8 20 2 5 -7 256 -14 256 -6 0 1 -5 16 -11 34
l-11 31 -138 0 c-77 0 -141 -3 -143 -7z m779 -36 c17 6 49 -17 35 -26 -6 -4
-45 -6 -88 -6 -66 1 -78 4 -81 19 -3 16 2 17 52 11 44 -6 56 -4 57 6 0 10 2
10 5 2 3 -7 12 -9 20 -6z m-311 -88 c6 0 19 0 30 0 54 1 95 -5 95 -14 0 -5 5
-3 10 6 6 11 19 15 37 12 46 -7 287 -6 311 1 17 6 22 3 22 -11 0 -35 -35 -56
-51 -31 -5 7 -9 9 -9 3 0 -6 -30 -9 -75 -8 -43 1 -75 -2 -75 -7 0 -6 -7 -10
-15 -10 -8 0 -15 4 -15 8 0 5 -32 9 -71 10 -62 1 -70 -1 -67 -16 3 -15 18 -17
108 -18 134 -1 132 -1 129 -21 -1 -10 3 -31 9 -46 28 -66 1 -150 -54 -173 -19
-8 -67 -14 -109 -14 l-75 0 0 111 0 111 -45 -4 c-30 -2 -45 1 -45 9 0 6 -6 18
-14 25 -11 12 -7 14 26 15 22 1 36 -2 33 -8 -4 -5 3 -9 13 -9 15 0 19 5 15 19
-4 17 -14 19 -109 17 -101 -3 -104 -2 -104 19 0 31 10 37 50 31 19 -3 40 -6
45 -7z m589 -3 c16 0 31 -6 33 -13 6 -17 -26 -30 -67 -29 -30 1 -35 5 -38 28
-3 24 0 26 20 21 13 -3 36 -6 52 -7z m-737 -47 l1 -34 -8 35 c-5 24 -8 28 -9
13 -1 -27 -21 -31 -21 -5 0 10 -5 23 -12 30 -8 8 -4 9 18 3 24 -6 29 -13 31
-42z m-66 14 c8 -21 5 -23 -26 -23 -26 0 -35 4 -36 18 -1 16 -2 16 -6 0 -7
-26 -43 -23 -43 3 0 11 4 18 9 14 5 -3 12 -1 15 3 3 5 22 9 42 8 28 -1 39 -6
45 -23z m695 -46 c1 -1 -2 -12 -7 -25 -7 -20 -11 -21 -29 -10 -15 9 -22 10
-31 1 -13 -13 -30 -1 -42 30 -6 16 -1 17 51 11 31 -3 58 -6 58 -7z m-951 -17
c10 -11 16 -20 13 -20 -3 0 -13 9 -23 20 -10 11 -16 20 -13 20 3 0 13 -9 23
-20z m142 14 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-50 -20
c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m120 -10 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-256 -41 c13 -16 12 -17 -3 -4 -17
13 -22 21 -14 21 2 0 10 -8 17 -17z m300 -20 c13 -16 12 -17 -3 -4 -17 13 -22
21 -14 21 2 0 10 -8 17 -17z m-91 -418 c0 -6 -8 -11 -19 -11 -10 0 -17 5 -14
11 2 7 10 12 18 12 8 0 15 -5 15 -12z m66 3 c-10 -6 -22 -6 -26 -2 -5 5 3 10
18 11 22 1 24 0 8 -9z m44 2 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10
11 10 2 0 4 -4 4 -10z m35 0 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4
10 3 0 8 -4 11 -10z m203 -107 c-10 -2 -28 -2 -40 0 -13 2 -5 4 17 4 22 1 32
-1 23 -4z m-621 -69 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m307 -3 c-9 -9 -34 -9 -40 0 -3 5 6 8 20 7 14 -1 23 -4 20 -7z m74 2 c-10 -2
-26 -2 -35 0 -10 3 -2 5 17 5 19 0 27 -2 18 -5z"/>
<path d="M1376 8594 c-3 -9 -6 -28 -6 -42 l0 -26 16 23 c9 12 30 31 48 42 l31
19 -41 0 c-29 0 -44 -5 -48 -16z"/>
<path d="M1405 8546 c-19 -15 -18 -15 18 -16 27 0 37 4 35 13 -7 18 -31 20
-53 3z"/>
<path d="M1447 8481 c-4 -19 -5 -36 -2 -39 10 -11 16 7 13 40 l-3 33 -8 -34z"/>
<path d="M747 8493 c-11 -10 -8 -63 3 -63 10 0 30 41 30 61 0 10 -23 12 -33 2z"/>
<path d="M1372 8460 c3 -32 6 -35 38 -37 32 -2 33 -2 11 7 -23 10 -24 11 -5
33 18 21 18 21 0 7 -16 -13 -20 -12 -33 5 -13 19 -14 18 -11 -15z"/>
<path d="M4046 7353 c-9 -26 -16 -54 -16 -64 0 -9 -7 -30 -15 -45 -25 -48 -20
-61 22 -57 21 1 43 5 51 8 8 3 10 0 6 -6 -4 -8 1 -9 16 -4 15 5 25 2 31 -6 7
-12 9 -12 9 0 0 9 8 11 27 6 23 -5 25 -4 14 9 -11 13 -9 13 13 3 14 -7 26 -18
26 -24 0 -7 6 -13 13 -13 29 -1 -21 -18 -58 -20 -82 -4 -103 0 -108 18 -4 14
-5 14 -6 -3 -1 -18 -7 -20 -41 -17 -22 2 -40 9 -40 16 -1 10 -209 -641 -210
-656 0 -3 71 -6 158 -6 86 0 164 -2 173 -6 13 -6 26 27 75 193 32 110 61 197
65 193 6 -8 108 -359 115 -398 l4 -24 -120 0 -120 0 0 -64 c0 -36 -4 -67 -10
-71 -18 -11 -372 -7 -383 4 -11 11 -17 -4 -61 -151 l-15 -48 594 0 593 0 -34
113 c-19 61 -54 175 -79 252 -25 77 -80 254 -124 393 -43 139 -81 255 -85 259
-3 4 -13 -2 -21 -13 -13 -18 -14 -16 -15 24 0 23 -4 42 -10 42 -5 0 -10 -11
-10 -25 0 -14 -4 -25 -10 -25 -5 0 -10 11 -10 25 0 14 -5 25 -11 25 -5 0 -8 4
-4 9 3 6 18 6 37 0 38 -11 41 -9 32 25 -6 24 -15 31 -38 27 -4 -1 -14 4 -22
10 -11 9 -14 9 -14 0 0 -19 -34 -8 -42 14 -6 19 -6 19 -7 -2 -1 -16 -7 -21
-23 -21 -13 1 -29 6 -35 11 -9 7 -13 5 -13 -7 0 -9 -8 -16 -20 -16 -12 0 -20
7 -20 18 0 11 -3 13 -8 6 -4 -7 -19 -12 -34 -12 -19 0 -28 6 -31 22 -4 20 -4
20 -6 -2 0 -13 -5 -20 -11 -17 -5 3 -10 2 -10 -4 0 -7 -8 -7 -25 -1 -14 5 -25
6 -26 2 0 -4 -4 2 -9 13 -7 17 -9 17 -9 3 -1 -21 -17 -24 -24 -5 -4 9 -6 9 -6
-1 -2 -26 -21 0 -21 29 0 30 23 41 34 17 5 -10 7 -10 12 0 6 13 34 17 34 4 0
-4 27 -7 60 -6 33 1 63 -2 66 -7 3 -5 14 -3 25 4 16 10 21 9 28 -2 5 -8 11 -9
15 -3 7 12 85 11 103 -1 6 -4 15 -2 18 4 4 5 13 8 21 5 17 -7 17 -9 -2 47
l-16 47 -193 0 -193 0 -16 -47z m182 -8 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7
-7 10 -15 7 -18z m145 -142 c5 15 47 -29 47 -50 0 -20 -20 -16 -21 5 -1 16 -2
16 -6 0 -5 -21 -73 -26 -73 -6 0 6 -7 3 -15 -8 -11 -14 -14 -15 -15 -4 0 13
-1 13 -10 0 -9 -15 -9 -16 -25 35 -3 12 2 14 20 11 14 -3 31 1 38 8 8 9 19 11
34 5 14 -5 23 -4 26 4z m-323 -118 c13 -14 21 -25 18 -25 -2 0 -15 11 -28 25
-13 14 -21 25 -18 25 2 0 15 -11 28 -25z m70 -1 c0 -2 -9 0 -20 6 -11 6 -20
13 -20 16 0 2 9 0 20 -6 11 -6 20 -13 20 -16z m107 20 c-3 -3 -12 -4 -19 -1
-8 3 -5 6 6 6 11 1 17 -2 13 -5z m56 -11 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5
14 0 19 -2 13 -5z m180 0 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13
-5z m-90 -10 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m147 -3
c8 -5 11 -10 5 -10 -5 0 -17 5 -25 10 -8 5 -10 10 -5 10 6 0 17 -5 25 -10z
m-257 -17 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-282 -50
c13 -16 12 -17 -3 -4 -10 7 -18 15 -18 17 0 8 8 3 21 -13z m246 -29 c-3 -3
-12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-60 -90 c-3 -3 -12 -4 -19 -1
-8 3 -5 6 6 6 11 1 17 -2 13 -5z m310 -16 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13
3 -3 4 -12 1 -19z m-136 -5 c13 -16 12 -17 -3 -4 -10 7 -18 15 -18 17 0 8 8 3
21 -13z m216 -85 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m0
-120 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-509 -115 c-15 -2
-42 -2 -60 0 -18 2 -6 4 27 4 33 0 48 -2 33 -4z m0 -30 c-21 -2 -55 -2 -75 0
-21 2 -4 4 37 4 41 0 58 -2 38 -4z m202 -123 c0 -11 -7 -20 -15 -20 -8 0 -15
9 -15 20 0 11 7 20 15 20 8 0 15 -9 15 -20z m240 -15 c0 -5 -5 -3 -10 5 -8 12
-10 12 -10 1 0 -8 -2 -12 -5 -9 -3 3 -2 13 1 22 5 14 9 15 15 4 5 -7 9 -17 9
-23z m55 25 c3 -5 12 -7 20 -3 10 3 15 -1 15 -11 0 -12 -7 -15 -26 -12 -30 6
-45 16 -23 16 11 0 11 2 -1 10 -9 6 -10 10 -3 10 6 0 14 -4 18 -10z m112 -22
c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-353 11 c-3 -5 -1 -9 5
-9 5 0 13 5 16 10 9 15 25 12 25 -4 0 -9 -14 -13 -45 -13 -29 0 -45 4 -45 12
0 7 11 13 25 13 13 0 22 -4 19 -9z m96 -6 c0 -15 -2 -16 -10 -3 -9 13 -11 13
-20 0 -8 -13 -10 -12 -10 3 0 10 8 17 20 17 12 0 20 -7 20 -17z m32 2 c0 -5
-5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5 15 11 13 6 -2 11 -8 11 -13z m175 0
c0 -8 -4 -15 -9 -15 -4 0 -8 7 -8 15 0 8 4 15 8 15 5 0 9 -7 9 -15z m33 0 c0
-8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m70 0
c0 -8 -4 -15 -9 -15 -13 0 -22 16 -14 24 11 11 23 6 23 -9z m-915 -115 c3 -6
-1 -7 -9 -4 -18 7 -21 14 -7 14 6 0 13 -4 16 -10z m525 -65 c0 -2 -10 -9 -22
-15 -22 -11 -22 -10 -4 4 21 17 26 19 26 11z"/>
<path d="M5050 7385 c0 -19 32 -17 36 3 1 7 0 8 -3 2 -3 -8 -9 -7 -19 1 -11 9
-14 8 -14 -6z"/>
<path d="M5112 7385 c-11 -12 -10 -15 3 -15 8 0 15 7 15 15 0 18 -2 18 -18 0z"/>
<path d="M4950 6774 l0 -544 185 0 185 0 0 75 0 74 -117 3 -118 3 -3 55 c-1
30 2 61 6 68 6 9 45 13 144 14 75 0 141 -3 147 -6 6 -4 11 -34 11 -67 l0 -59
96 0 c124 0 234 24 234 51 0 10 5 19 10 19 6 0 10 -6 10 -12 0 -9 4 -8 10 2 5
8 10 12 10 8 0 -19 66 20 114 68 98 99 146 224 146 383 0 151 -52 285 -143
367 l-49 44 -439 -1 -439 0 0 -545z m98 499 c-10 -2 -26 -2 -35 0 -10 3 -2 5
17 5 19 0 27 -2 18 -5z m491 -30 c3 -14 1 -15 -12 -5 -11 9 -17 10 -22 2 -8
-13 -46 -10 -58 5 -5 6 -7 1 -4 -12 5 -22 4 -22 -21 -5 -14 9 -29 16 -32 14
-3 -2 -6 3 -8 10 -2 10 15 13 75 11 66 -2 79 -5 82 -20z m53 17 c19 0 2 -23
-20 -27 -17 -2 -22 2 -22 18 0 15 4 19 16 15 9 -3 20 -6 26 -6z m-341 -37 c13
-16 12 -17 -3 -4 -10 7 -18 15 -18 17 0 8 8 3 21 -13z m-30 -40 c13 -16 12
-17 -3 -4 -10 7 -18 15 -18 17 0 8 8 3 21 -13z m-111 -35 c0 -6 3 -8 7 -5 3 4
12 2 20 -4 8 -7 13 -8 13 -1 0 5 14 12 30 14 17 3 30 2 30 -2 0 -3 21 -6 48
-6 40 1 47 -3 50 -20 3 -19 -2 -21 -63 -20 -47 1 -69 6 -75 16 -8 12 -10 11
-10 -5 0 -17 -2 -17 -10 -5 -8 12 -10 12 -10 -5 -1 -19 -2 -19 -20 4 -18 21
-20 21 -21 5 0 -18 -1 -17 -9 1 -6 14 -9 15 -9 5 -1 -9 -11 -17 -26 -18 -21
-1 -25 2 -25 27 0 27 3 29 40 29 23 1 40 -4 40 -10z m243 0 c4 -10 8 -9 16 2
8 10 13 12 19 4 4 -6 36 -10 75 -9 37 1 67 -3 67 -8 0 -6 5 -6 13 0 6 6 45 9
85 8 39 0 72 3 72 7 0 5 5 6 10 3 6 -3 10 -17 10 -30 0 -22 -3 -23 -60 -21
-38 2 -60 -1 -60 -8 0 -7 -3 -7 -8 -1 -9 13 -62 14 -62 2 0 -5 -12 -6 -27 -2
-15 3 -32 7 -38 8 -5 1 -13 7 -17 12 -5 7 -8 6 -8 0 0 -6 -24 -11 -55 -12 -54
0 -55 0 -55 28 0 29 14 39 23 17z m442 -15 c-4 -16 -8 -33 -8 -40 0 -7 -8 -6
-24 4 -13 9 -23 22 -21 30 5 22 22 17 30 -9 5 -20 6 -18 7 10 1 17 6 32 12 32
7 0 9 -10 4 -27z m35 15 c0 -9 3 -8 10 2 14 23 18 8 6 -22 l-12 -28 -11 30
c-6 17 -7 30 -2 30 5 0 9 -6 9 -12z m116 -15 c-4 -8 -6 -18 -6 -22 0 -11 -74
-8 -82 3 -12 18 9 31 52 32 32 1 40 -2 36 -13z m-668 -85 c9 -3 23 -3 30 0 10
3 10 7 0 20 -11 15 -11 15 2 5 19 -15 230 -22 230 -8 0 7 3 7 8 0 10 -14 32
-17 32 -4 0 6 7 4 15 -5 8 -8 15 -19 15 -24 0 -5 8 -24 18 -43 25 -50 23 -144
-6 -191 -32 -52 -80 -68 -202 -68 l-100 0 0 140 c0 138 0 140 -22 141 -13 1
-29 0 -37 -1 -8 -1 -18 4 -22 12 -7 11 -11 10 -19 -2 -7 -12 -10 -8 -10 17 0
25 5 32 23 35 12 2 23 -2 25 -8 2 -7 11 -14 20 -16z m543 15 c13 -16 12 -17
-3 -4 -10 7 -18 15 -18 17 0 8 8 3 21 -13z m-157 -15 c-3 -13 -10 -30 -15 -38
-7 -12 -8 -10 -9 7 0 12 -3 28 -6 37 -4 12 0 16 16 16 18 0 20 -4 14 -22z m99
-1 c7 4 8 0 4 -11 -4 -10 -7 -22 -7 -27 0 -5 -5 -9 -11 -9 -5 0 -8 4 -5 8 6 9
-47 7 -63 -3 -7 -4 -11 4 -11 19 0 21 5 25 38 28 20 2 39 0 40 -5 2 -4 9 -4
15 0z m98 -14 c13 -16 12 -17 -3 -4 -10 7 -18 15 -18 17 0 8 8 3 21 -13z
m-704 -29 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-97 -8 c0
-2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z m627 -258 c-3 -8 -6 -5
-6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-666 -75 c13 -16 12 -17 -3 -4 -10 7
-18 15 -18 17 0 8 8 3 21 -13z m406 -9 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11
1 17 -2 13 -5z m-436 -41 c13 -16 12 -17 -3 -4 -10 7 -18 15 -18 17 0 8 8 3
21 -13z m15 -75 l29 -33 -32 29 c-31 28 -38 36 -30 36 2 0 16 -15 33 -32z
m153 6 c14 -12 -19 -1 -35 12 -18 14 -18 14 6 3 14 -6 27 -13 29 -15z m124 -1
c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m60 0 c-7 -2 -21 -2
-30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m131 -98 c3 -8 1 -15 -4 -15 -6 0
-10 7 -10 15 0 8 2 15 4 15 2 0 6 -7 10 -15z m36 -3 c0 -12 -3 -12 -15 -2 -8
7 -15 13 -15 15 0 1 7 2 15 2 8 0 15 -7 15 -15z m47 3 c0 -8 -4 -12 -9 -9 -5
3 -6 10 -3 15 9 13 12 11 12 -6z m37 0 c-4 -8 -7 -15 -9 -15 -2 0 -5 7 -9 15
-3 9 0 15 9 15 9 0 12 -6 9 -15z m31 6 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9
0 18 6 21 15 7z m40 -1 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3
0 8 -4 11 -10z m40 1 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z
m-698 -47 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m242 -90 c14
-12 -19 -1 -35 12 -18 14 -18 14 6 3 14 -6 27 -13 29 -15z"/>
<path d="M5390 7249 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M5221 6056 c-8 -9 -11 -52 -9 -127 l3 -114 55 -2 55 -3 -37 16 c-21
9 -38 20 -38 25 0 5 16 19 35 31 19 13 35 29 35 37 0 13 -2 12 -12 0 -7 -9
-26 -23 -42 -33 -35 -22 -46 -11 -46 50 0 48 25 85 70 104 16 7 30 16 30 21 0
15 -86 10 -99 -5z"/>
<path d="M5240 5975 c-11 -13 -6 -15 34 -15 34 0 46 4 46 15 0 10 -10 15 -34
15 -18 0 -39 -7 -46 -15z"/>
<path d="M3907 5893 c-14 -13 -8 -83 7 -83 13 0 36 48 36 76 0 14 -31 19 -43
7z"/>
<path d="M5300 5881 c0 -5 -3 -16 -6 -25 -4 -11 -1 -16 10 -16 10 0 16 9 16
25 0 14 -4 25 -10 25 -5 0 -10 -4 -10 -9z"/>
<path d="M2140 3845 l0 -265 50 0 50 0 0 265 0 265 -50 0 -50 0 0 -265z"/>
<path d="M5780 4075 l0 -35 50 0 50 0 0 35 0 35 -50 0 -50 0 0 -35z"/>
<path d="M7040 4075 l0 -35 50 0 50 0 0 35 0 35 -50 0 -50 0 0 -35z"/>
<path d="M1240 4040 l0 -40 85 0 85 0 0 -210 0 -210 50 0 50 0 0 210 0 210 80
0 80 0 0 40 0 40 -215 0 -215 0 0 -40z"/>
<path d="M6837 4043 c-2 -5 -10 -25 -16 -45 -10 -30 -18 -38 -37 -38 -21 0
-24 -5 -24 -35 0 -28 4 -35 20 -35 19 0 20 -7 20 -115 0 -176 16 -200 126
-193 47 3 49 4 52 34 3 28 1 31 -21 27 -46 -9 -57 17 -57 138 l0 109 35 0 c33
0 35 2 35 35 0 33 -2 35 -35 35 -35 0 -35 0 -35 45 0 43 -1 45 -29 45 -17 0
-32 -3 -34 -7z"/>
<path d="M1805 3946 c-118 -52 -129 -269 -16 -343 40 -27 131 -31 185 -9 30
12 76 58 76 76 0 4 -18 10 -40 13 -34 4 -45 1 -65 -19 -41 -41 -96 -27 -121
31 -27 65 -27 65 112 65 l127 0 -7 47 c-13 98 -72 153 -165 153 -31 -1 -69 -7
-86 -14z m135 -66 c11 -11 20 -29 20 -40 0 -18 -7 -20 -76 -20 -74 0 -75 1
-68 23 17 57 84 77 124 37z"/>
<path d="M2415 3946 c-59 -26 -95 -93 -95 -174 0 -86 31 -146 92 -178 35 -18
129 -18 172 0 30 12 76 58 76 76 0 4 -18 10 -40 13 -34 4 -45 1 -65 -19 -41
-41 -96 -27 -121 31 -27 65 -27 65 112 65 l127 0 -7 47 c-13 98 -72 153 -165
153 -31 -1 -69 -7 -86 -14z m135 -66 c11 -11 20 -29 20 -40 0 -18 -7 -20 -76
-20 -74 0 -75 1 -68 23 17 57 84 77 124 37z"/>
<path d="M2807 3938 c-108 -63 -107 -281 3 -340 48 -25 139 -24 190 2 39 20
51 34 70 84 9 26 9 26 -39 26 -41 0 -49 -3 -59 -25 -27 -59 -110 -54 -131 7
-16 44 -13 134 4 168 19 36 66 48 100 26 14 -9 25 -23 25 -31 0 -11 13 -15 50
-15 31 0 50 4 50 11 0 25 -42 78 -74 93 -50 24 -142 21 -189 -6z"/>
<path d="M3224 3941 c-63 -29 -89 -78 -89 -171 0 -90 25 -139 86 -170 46 -24
143 -27 192 -6 130 54 137 285 12 346 -54 26 -145 26 -201 1z m158 -71 c29
-30 30 -33 26 -106 -3 -69 -6 -79 -31 -100 -43 -37 -102 -26 -128 24 -18 32
-18 132 0 164 28 54 89 62 133 18z"/>
<path d="M3598 3770 l3 -190 43 0 44 0 4 125 c4 138 19 177 71 183 47 6 57
-26 57 -175 l0 -133 50 0 50 0 0 115 c0 133 14 180 54 191 63 15 76 -16 76
-182 l0 -124 50 0 50 0 0 128 c0 201 -25 252 -121 252 -44 0 -55 -4 -80 -31
-16 -17 -29 -36 -29 -43 -1 -6 -8 3 -17 19 -24 44 -42 54 -97 55 -43 0 -53 -4
-82 -36 l-34 -35 0 35 0 36 -47 0 -48 0 3 -190z"/>
<path d="M4240 3770 l0 -190 50 0 50 0 0 104 c0 57 3 117 6 134 9 43 40 74 72
70 42 -5 52 -38 52 -180 l0 -128 50 0 50 0 0 123 c0 155 20 199 83 183 36 -9
47 -54 47 -193 l0 -113 45 0 45 0 0 150 c0 198 -15 230 -110 230 -51 0 -76
-13 -100 -52 -17 -28 -18 -29 -25 -9 -11 36 -54 61 -102 61 -38 0 -50 -5 -79
-34 l-34 -34 0 34 0 34 -50 0 -50 0 0 -190z"/>
<path d="M4892 3799 l3 -161 33 -29 c26 -23 42 -29 78 -29 53 0 81 12 111 48
l23 27 0 -37 0 -38 48 0 47 0 -3 190 -3 190 -44 0 -45 0 0 -97 c-1 -160 -23
-213 -91 -213 -50 0 -63 35 -68 182 l-3 128 -45 0 -44 0 3 -161z"/>
<path d="M5340 3770 l0 -190 50 0 50 0 0 120 c0 155 16 190 84 190 13 0 31 -9
42 -21 16 -18 19 -40 22 -155 l4 -134 50 0 50 0 -4 148 c-5 199 -24 232 -134
232 -39 0 -51 -5 -81 -35 -19 -19 -37 -35 -39 -35 -2 0 -4 16 -4 35 0 35 0 35
-45 35 l-45 0 0 -190z"/>
<path d="M5780 3770 l0 -190 50 0 50 0 0 190 0 190 -50 0 -50 0 0 -190z"/>
<path d="M6049 3938 c-55 -32 -81 -85 -81 -168 0 -126 59 -190 175 -190 91 0
138 30 167 104 9 26 9 26 -39 26 -41 0 -49 -3 -59 -25 -27 -59 -110 -54 -131
7 -14 41 -14 131 0 162 16 34 62 52 95 37 15 -6 28 -20 31 -31 4 -17 14 -20
54 -20 54 0 58 7 32 52 -39 66 -168 91 -244 46z"/>
<path d="M6445 3942 c-36 -22 -50 -39 -59 -69 -7 -22 -5 -23 43 -23 41 0 51 4
56 20 13 40 81 40 104 -1 25 -45 10 -59 -65 -59 -85 0 -131 -21 -151 -69 -35
-82 17 -161 105 -161 49 0 108 27 118 55 5 11 11 7 23 -17 15 -31 20 -33 74
-36 l57 -3 0 30 c0 23 -5 30 -22 33 -22 3 -23 9 -29 119 -7 128 -15 151 -62
179 -43 27 -150 27 -192 2z m155 -198 c0 -30 -31 -81 -57 -93 -50 -23 -94 20
-73 73 11 29 22 34 83 35 37 1 47 -2 47 -15z"/>
<path d="M7040 3770 l0 -190 50 0 50 0 0 190 0 190 -50 0 -50 0 0 -190z"/>
<path d="M7314 3941 c-63 -29 -89 -78 -89 -171 0 -61 4 -82 24 -113 31 -51 85
-77 160 -77 133 0 195 65 194 202 -1 75 -32 131 -88 158 -54 26 -145 26 -201
1z m158 -71 c29 -30 30 -33 26 -106 -3 -69 -6 -79 -31 -100 -43 -37 -102 -26
-128 24 -18 32 -18 132 0 164 28 54 89 62 133 18z"/>
<path d="M7688 3770 l3 -190 44 0 45 0 0 98 c1 162 25 217 94 210 51 -5 66
-49 66 -190 l0 -118 46 0 45 0 -3 161 -3 161 -33 29 c-26 23 -42 29 -78 29
-53 0 -80 -12 -111 -49 l-22 -26 -1 38 0 37 -47 0 -48 0 3 -190z"/>
<path d="M8182 3943 c-64 -31 -79 -114 -29 -161 13 -12 60 -31 112 -44 80 -20
90 -26 93 -47 5 -34 -22 -51 -80 -51 -38 0 -54 5 -71 23 -18 19 -30 23 -59 19
-44 -5 -47 -19 -13 -56 33 -36 65 -46 146 -46 117 0 169 36 169 116 0 66 -37
91 -179 123 -88 19 -79 81 12 81 19 0 46 -9 60 -20 29 -23 97 -28 97 -7 0 23
-32 59 -66 73 -45 19 -151 18 -192 -3z"/>
</g>
</svg>



		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
